// Api Slice
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { campaignEndpoints } from "./endpoints/campaignEndpoints";
import { placementEndpoints } from "./endpoints/placementEndpoints";
import { reportEndpoints } from "./endpoints/reportEndpoints";
import { opsEndpoints } from "./endpoints/opsEndpoints";

const baseQuery = fetchBaseQuery({
  baseUrl:
    process.env.REACT_APP_DEV === "development"
      ? "https://manager-edge-d.adquire.com/apiv1/"
      : "https://manager-edge.adquire.com/apiv1/", // To move somewhere else.
  prepareHeaders: (headers, { getState }) => {
    // Get auth token from store.
    const token = getState().auth.user.id_token;

    if (token) {
      headers.set("authorization", token); // Set authorization in header.
    }

    return headers;
  },
});

export const adquireApi = createApi({
  reducerPath: "adquireApi",
  baseQuery: baseQuery,

  // List of tagTypes available for cache.
  tagTypes: [
    "Campaign",
    "Placement",
    "Configuration",
    "Category",
    "Criteria",
    "Question",
    "Creative",
    "CampaignGroup",
    "PlacementGroup",
    "Association",
    "BadWords",
    "BlockedEmailDomains",
    "DomainsToReview",
    "BlockedEmails",
    "BlockedAreaCodes",
    "Customer",
    "Validator",
    "CustomValidation",
    "LeadDelivery",
    "ContactNotification",
    "ExternalAccount",
    "DarwinGroup",
    "DarwinGroupMember",
    "Deliverable",
    "Reviewable",
  ],

  // Endpoint definitions:
  endpoints: (builder) => ({
    ...campaignEndpoints(builder),
    ...placementEndpoints(builder),
    ...reportEndpoints(builder),
    ...opsEndpoints(builder),
  }),
});

// Export query hooks.
export const {
  // Campaigns
  useGetCampaignsQuery,
  useGetCampaignByIdQuery,
  useLazyGetCampaignByIdQuery,
  useUpdateCampaignByIdMutation,
  useSearchCampaignsMutation,
  useGetCustomersQuery,
  useUpdateCustomersMutation,
  // useGetSalesPersonQuery,
  useGetValidatorsQuery,
  useGetDefaultValidatorsQuery,
  useGetCampaignValidationDataMutation,
  useGetCampaignCustomValidationQuery,
  useTestCustomValidationMutation,
  useSaveCustomValidationMutation,
  useGetCampaignLeadDeliveryQuery,
  useSaveCampaignLeadDeliveryMutation,
  useGetLatestDeliveriesQuery,
  useCreateDeliveryFromQueryMutation,
  useDeleteLeadDeliveryConfigMutation,
  useCloneLeadDeliveryConfigMutation,
  useTestDeliveryMutation,
  useGetCustomFieldsQuery,
  useAddDefaultValidatorMutation,
  useRemoveDefaultValidatorMutation,
  useDisableValidatorMutation,
  useGetEmailValidatorConfigurationQuery,
  useGetDefaultPhoneConfigurationQuery,
  useGetPhoneValidatorConfigurationQuery,
  useGetDefaultAddressConfigurationQuery,
  useGetAddressValidatorConfigurationQuery,
  useGetCustomValidatorConfigurationQuery,
  useUpdatePhoneValidatorConfigurationMutation,
  useUpdateEmailValidatorConfigurationMutation,
  useUpdateAddressValidatorConfigurationMutation,
  useGetCategoriesByIdQuery,
  useGetAllCategoriesQuery,
  useUpdateCategoriesByIdMutation,
  useGetCampaignCriteriaQuery,
  useUpdateCampaignCriteriaMutation,
  usePreviewPrePingMutation,
  useTestPrePingMutation,
  useGetCampaignQuestionsQuery,
  useUpdateCampaignQuestionMutation,
  useGetStatsQuery,
  useLazyGetStatsQuery,
  useRemoveCampaignQuestionMutation,
  useReorderCampaignQuestionsMutation,
  useAddCampaignQuestionMutation,
  useGetCampaignCreativeQuery,
  useGetAllCampaignCreativePercentagesQuery,
  useGetCampaignCreativePreviewMutation,
  useUpdateCreativeMutation,
  useNewCreativeMutation,
  useGetCreativeCriteriaQuery,
  useUpdateCreativeCriteriaMutation,
  useRemoveCreativeMutation,
  useGetCampaignGroupsQuery,
  useUpdateCampaignGroupingMutation,
  useRemoveCampaignFromGroupMutation,
  useGetCampaignAssociationsQuery,
  useUpdateCampaignAssociationsMutation,

  // Placements
  useGetPlacementsQuery,
  useGetPlacementByIdQuery,
  useLazyGetPlacementByIdQuery,
  useUpdatePlacementByIdMutation,
  useNewPlacementMutation,
  useGetRankersQuery,
  useGetLeadAcceptanceQuery,
  useUpdateLeadAcceptanceMutation,
  useGetPlacementAddressValidationQuery,
  useUpdatePlacementAddressValidationMutation,
  useGetPlacementCriteriaQuery,
  useUpdatePlacementCriteriaMutation,
  useRemovePlacementCriteriaMutation,
  useGetPlacementGroupsQuery,
  useUpdatePlacementGroupingMutation,
  useRemovePlacementFromGroupMutation,

  //Ops
  useUploadEmailSuppressionMutation,
  useSearchZipCodesMutation,
  useGetBadWordsQuery,
  useAddBadWordMutation,
  useTestBadWordQuery,
  useRemoveBadWordMutation,
  useGetIpLookupMutation,
  useGetAllBlockedEmailDomainsQuery,
  useGetBlockedEmailsQuery,
  useGetEmailDomainsToReviewQuery,
  useSetEmailDomainAsReviewedMutation,
  useUpdateBlockedEmailDomainMutation,
  useUpdateBulkBlockedEmailDomainMutation,
  useRemoveBlockedEmailDomainMutation,
  useUpdateBulkBlockedEmailsMutation,
  useValidateBlockedEmailsMutation,
  useRemoveBlockedEmailMutation,
  useGetBlockedAreaCodesQuery,
  useUpdateBulkBlockedAreaCodesMutation,
  useRemoveBlockedAreaCodesMutation,
  useValidateBlockedAreaCodeMutation,
  useAddKeyCloakAccountMutation,
  useGetContactNotificationConfigurationsQuery,
  useSaveContactNotificationConfigurationMutation,
  useDeleteContactNotificationConfigurationMutation,
  useLoadDarwinGroupsQuery,
  useLoadDarwinGroupMembersQuery,
  useSaveDarwinGroupMutation,
  useAddDarwinGroupMemeberMutation,
  useDeleteDarwinGroupMemberMutation,
  useGetExternalAccountsQuery,
  useLazyGetExternalAccountsQuery,

  // Reports
  useLoadAdqPerformanceReportMutation,
  useLoadValidationReportMutation,
  useLoadBrokeredLeadViewerMutation,
  useLoadBrokeredFailedLeadsMutation,
  useLoadTruIntentReportMutation,
  useLoadTruIntentFailedLeadsMutation,
  useLoadTruIntentFailedRtsMutation,
  useLoadSessionViewerMutation,
  useGetSessionDetailsMutation,
  useGetPubPrevalidationByDateMutation,
  useGetPubPrevalidationByMessageMutation,
  useLoadActivityLogMutation,
  useLoadCampaignQuickStatusMutation,
  useLoadInternalBudgetMutation,
  useLoadBuySheetMutation,
  useLoadDeliverableViewerQuery,
  useUpdateDeliverableViewerStatusMutation,
  useLazyLoadQuarantineQuery,
  useLoadCampaignAnalyticsReportMutation,
  useLoadAddressValidationBreakdownReportMutation,
  useUpdateBulkReviewStatusMutation,
} = adquireApi;
