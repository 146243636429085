export const campaignEndpoints = (builder) => ({
  getCampaigns: builder.query({
    query: (
      options = {
        thin: "yes",
        includeArchived: "no",
        includeLegacy: "no",
      }
    ) => ({
      url: `/campaigns/campaigns/all/?thin=${options.thin}&include_archived=${options.includeArchived}&include_legacy_archived=${options.includeLegacy}`,
    }),

    transformResponse: (response) => {
      return response.records.map((campaign) => {
        let { rates, isArchived, isPaused, startDate, endDate } = campaign;
        let rate = rates.length ? rates[0].rate : 0; // Get campaign rate. (will change)
        let currentTimestamp = parseInt(Date.now() / 1000);
        let inactive =
          isPaused ||
          isArchived ||
          (parseInt(startDate) > 0 &&
            parseInt(endDate) !== 0 &&
            parseInt(endDate) < currentTimestamp);

        return { isActive: !inactive, rate, ...campaign };
      });
    },

    // Provides tags for cached campaign data.
    providesTags: (result, error, arg) => {
      return result
        ? [
            ...result.map(({ UUID }) => ({ type: "Campaign", id: UUID })),
            "Campaign",
          ]
        : ["Campaign"];
    },
  }),

  getCampaignById: builder.query({
    query: (options) => ({
      url: `/campaigns/campaigns/${options.UUID}`,
    }),

    transformResponse: (response) => {
      let { rates, isArchived, isPaused, startDate, endDate } = response;
      let rate = rates.length ? rates[0].rate : 0; // Get campaign rate. (will change)
      let currentTimestamp = parseInt(Date.now() / 1000);

      let inactive =
        isPaused ||
        isArchived ||
        (parseInt(startDate) > 0 &&
          parseInt(endDate) !== 0 &&
          parseInt(endDate) < currentTimestamp);

      return { isActive: !inactive, rate, ...response };
    },

    providesTags: (result, error, arg) => {
      return result ? [{ type: "Campaign", id: result.UUID }] : ["Campaign"];
    },
  }),

  updateCampaignById: builder.mutation({
    query: (options = { isNew: false, data: {} }) => ({
      url: `/campaigns/campaigns/${options.isNew ? "" : options.data.UUID}`,
      method: options.isNew ? "POST" : "PUT",
      body: options.data,
    }),

    // Invalidates cache for the specified campaign for automatic updates.
    invalidatesTags: (result, error, arg) => {
      return [{ type: "Campaign", id: arg.UUID }];
    },
  }),

  searchCampaigns: builder.mutation({
    query: (body) => {
      return {
        url: `/campaigns/search/`,
        method: "POST",
        body,
      };
    },
  }),

  getDefaultValidators: builder.query({
    query: (options) => ({
      url: `campaigns/default-validators`,
    }),

    providesTags: (result, error, arg) => {
      return result
        ? [
            ...result.validators.map((v) => ({
              type: "Validator",
              id: v.service_name,
            })),
            "Validator",
          ]
        : ["Validator"];
    },
  }),

  getCampaignValidationData: builder.mutation({
    query: (body) => ({
      url: `campaigns/validation-data`,
      method: "POST",
      body: { records: [] },
    }),
  }),

  getCampaignCustomValidation: builder.query({
    query: (options) => ({
      url: `custom-validation/configuration/${options.UUID}`,
    }),
    providesTags: ["CustomValidation"],
  }),

  testCustomValidation: builder.mutation({
    query: (body) => ({
      url: `custom-validation/test`,
      method: "POST",
      body,
    }),
  }),

  saveCustomValidation: builder.mutation({
    query: (body) => ({
      url: `custom-validation/configuration/${body.UUID}`,
      method: "POST",
      body,
    }),

    invalidatesTags: ["CustomValidation"],
  }),

  getCampaignLeadDelivery: builder.query({
    query: (options) => ({
      url: `deliverable-manager/configurations/?limit=500&offset=0&campaign=${options.UUID}`,
    }),
    transformResponse: (response) => response.records,
    providesTags: (result, error, arg) => {
      return result
        ? [
            ...result.map((l) => ({ type: "LeadDelivery", id: l.UUID })),
            "LeadDelivery",
          ]
        : ["LeadDelivery"];
    },
  }),

  saveCampaignLeadDelivery: builder.mutation({
    query: (body) => ({
      url: `deliverable-manager/configurations/${body.UUID}`,
      method: "POST",
      body,
    }),

    invalidatesTags: (result, error, arg) => {
      return arg.hasOwnProperty("UUID")
        ? [{ type: "LeadDelivery", id: arg.UUID }]
        : ["LeadDelivery"];
    },
  }),

  getLatestDeliveries: builder.query({
    query: (body) => ({
      url: `deliverable-manager/deliveries/latest/${body.UUID}?limit=${body.limit}&offset=0`,
    }),
    transformResponse: (response) => response.records,
  }),

  createDeliveryFromQuery: builder.mutation({
    query: (body) => ({
      url: `deliverable-manager/deliveries/create-from-query/`,
      mutation: "POST",
      body,
    }),
  }),

  deleteLeadDeliveryConfig: builder.mutation({
    query: (body) => ({
      url: `deliverable-manager/configurations/${body.UUID}`,
      method: "DELETE",
    }),

    invalidatesTags: (result, error, arg) => {
      return arg.hasOwnProperty("UUID")
        ? [{ type: "LeadDelivery", id: arg.UUID }]
        : ["LeadDelivery"];
    },
  }),

  cloneLeadDeliveryConfig: builder.mutation({
    query: (body) => ({
      url: `deliverable-manager/clone-configuration/${body.UUID}`,
      method: "POST",
    }),

    invalidatesTags: (result, error, arg) => {
      return arg.hasOwnProperty("UUID")
        ? [{ type: "LeadDelivery", id: arg.UUID }]
        : ["LeadDelivery"];
    },
  }),

  testDelivery: builder.mutation({
    query: (body) => ({
      url: `deliverable-manager/test/?verbose=${body.verbose}`,
      method: "POST",
      body: body.data,
    }),
  }),

  getCustomFields: builder.query({
    query: (body) => ({
      url: `deliverable-manager/available-custom-fields/${body.UUID}`,
    }),
    transformResponse: (response) => response.records,
  }),

  addDefaultValidator: builder.mutation({
    query: ({ name }) => ({
      url: `campaigns/default-validators/${name}`,
      method: "PUT",
    }),

    invalidatesTags: (result, error, arg) => {
      return arg.hasOwnProperty("UUID")
        ? [{ type: "Validator", id: arg.name }]
        : ["Validator"];
    },
  }),

  removeDefaultValidator: builder.mutation({
    query: ({ name }) => ({
      url: `campaigns/default-validators/${name}`,
      method: "DELETE",
    }),

    invalidatesTags: (result, error, arg) => {
      return arg.hasOwnProperty("UUID")
        ? [{ type: "Validator", id: arg.name }]
        : ["Validator"];
    },
  }),

  disableValidator: builder.mutation({
    query: ({ name }) => ({
      url: `campaigns/disable-validator/${name}`,
      method: "PUT",
    }),
  }),

  getValidators: builder.query({
    query: (options = { verbose: true }) => ({
      url: `validation-coordinator/validators/?verbose=${options.verbose}`,
    }),
    providesTags: (result, error, arg) => {
      return result
        ? [
            ...result.map((v) => ({ type: "Validator", id: v.service_name })),
            "Validator",
          ]
        : ["Validator"];
    },
  }),

  getEmailValidatorConfiguration: builder.query({
    async queryFn(arg, queryApi, options, fetchWithBQ) {
      const campaignEmailConfiguration = await fetchWithBQ(
        `email-validation/configuration/${arg.UUID}`
      );

      if (
        campaignEmailConfiguration.error &&
        campaignEmailConfiguration.error.status === 404
      ) {
        return {
          data: {
            campaignUUID: arg.UUID,
            briteVerify: false,
            emailOversight: true,
            strictEmailOversight: false,
            towerData: false,
          },
        };
      }

      if (
        campaignEmailConfiguration.error &&
        campaignEmailConfiguration.error.status !== 404
      ) {
        throw campaignEmailConfiguration.error;
      }

      return { data: campaignEmailConfiguration.data };
    },

    // Provides tags for cached configuration data.
    providesTags: [{ type: "Configuration", id: "email" }, "Configuration"],
  }),

  updateEmailValidatorConfiguration: builder.mutation({
    query: (body) => ({
      url: `email-validation/configuration/${body.campaignUUID}`,
      method: "POST",
      body,
    }),

    // Invalidates cache for the specified configuration for automatic updates.
    invalidatesTags: [{ type: "Configuration", id: "email" }],
  }),

  getDefaultPhoneConfiguration: builder.query({
    query: (options) => ({
      url: "phone-verification/default-configuration",
    }),
  }),

  getPhoneValidatorConfiguration: builder.query({
    async queryFn(arg, queryApi, options, fetchWithBQ) {
      // Check if campaign has a phone validator configuration.
      const campaignPhoneConfiguration = await fetchWithBQ(
        `phone-verification/campaign-configurations/${arg.UUID}`
      );

      // If no configuration found:
      if (!campaignPhoneConfiguration.data) {
        // Get default configuration.
        const defaultPhoneConfiguration = await fetchWithBQ(
          "phone-verification/default-configuration"
        );

        // Return default configuration or error.
        return defaultPhoneConfiguration.data
          ? { data: { config: defaultPhoneConfiguration.data } }
          : { error: defaultPhoneConfiguration.error };
      }

      if (
        campaignPhoneConfiguration.error &&
        campaignPhoneConfiguration.error.status !== 404
      ) {
        throw campaignPhoneConfiguration.error;
      }

      return { data: campaignPhoneConfiguration.data };
    },

    // Provides tags for cached configuration data.
    providesTags: [{ type: "Configuration", id: "phone" }, "Configuration"],
  }),

  updatePhoneValidatorConfiguration: builder.mutation({
    query: (body) => ({
      url: `phone-verification/campaign-configurations/${body.campaignUUID}`,
      method: "POST",
      body,
    }),

    // Invalidates cache for the specified configuration for automatic updates.
    invalidatesTags: [{ type: "Configuration", id: "phone" }],
  }),

  getDefaultAddressConfiguration: builder.query({
    query: (options) => ({
      url: "address-validation/default-configuration",
    }),
  }),

  getAddressValidatorConfiguration: builder.query({
    async queryFn(arg, queryApi, options, fetchWithBQ) {
      // Check if campaign has an address validator configuration.
      const campaignAddressConfiguration = await fetchWithBQ(
        `address-validation/campaign-configurations/${arg.UUID}`
      );

      // If no configuration found:
      if (!campaignAddressConfiguration.data) {
        // Get default configuration.
        const defaultAddressConfiguration = await fetchWithBQ(
          "address-validation/default-configuration/"
        );

        // Return default configuration or error.
        return defaultAddressConfiguration.data
          ? { data: defaultAddressConfiguration.data }
          : { error: defaultAddressConfiguration.error };
      }

      if (
        campaignAddressConfiguration.error &&
        campaignAddressConfiguration.error.status !== 404
      ) {
        throw campaignAddressConfiguration.error;
      }

      return { data: campaignAddressConfiguration.data.config };
    },

    // Provides tags for cached configuration data.
    providesTags: [{ type: "Configuration", id: "address" }, "Configuration"],
  }),

  updateAddressValidatorConfiguration: builder.mutation({
    query: (body) => ({
      url: `address-validation/campaign-configurations/${body.UUID}`,
      method: "POST",
      body,
    }),

    // Invalidates cache for the specified configuration for automatic updates.
    invalidatesTags: [{ type: "Configuration", id: "address" }],
  }),

  getCustomValidatorConfiguration: builder.query({
    query: (options) => ({
      url: `custom-validation/configuration/${options.UUID}`,
    }),
  }),

  getAllCategories: builder.query({
    query: (options) => ({
      url: `tagging/tags`,
    }),
    transformResponse: (response) => response.records,
  }),

  getCategoriesById: builder.query({
    query: (options) => ({
      url: `tagging/association/${options.UUID}`,
      // url: `tagging/tags`,
    }),
    transformResponse: (response) => response.records,
    // Provides tags for cached campaign category data.
    providesTags: (result, error, arg) =>
      result
        ? [
            ...result.map(({ UUID }) => ({ type: "Category", id: UUID })),
            "Category",
          ]
        : ["Category"],
  }),

  updateCategoriesById: builder.mutation({
    query: (body) => ({
      url: `tagging/association`,
      method: "PUT",
      body,
    }),
    // Invalidates cache for the current campaign categories for automatic updates.
    invalidatesTags: (result, error, arg) => [
      { type: "Category", id: arg.UUID },
    ],
  }),

  getCampaignCriteria: builder.query({
    query: (options) => ({
      url: `criteria-filtering/criteria/${options.UUID}`,
    }),
    transformResponse: (response) => {
      return response.criteriaFilter;
    },
    providesTags: (result) =>
      result
        ? [{ type: "Criteria", id: result.campaignUuid }, "Criteria"]
        : ["Criteria"],
  }),

  updateCampaignCriteria: builder.mutation({
    query: (body) => ({
      url: `criteria-filtering/criteria/${body.campaignUuid}`,
      method: "POST",
      body,
    }),

    invalidatesTags: (result, error, arg) => {
      return [{ type: "Criteria", id: arg.campaignUuid }];
    },
  }),

  getStats: builder.query({
    async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
      let endDate = _arg.hasOwnProperty("endDate")
        ? new Date(_arg.endDate)
        : new Date();

      let startDate = _arg.hasOwnProperty("startDate")
        ? new Date(_arg.startDate)
        : new Date(
            endDate.getFullYear(),
            endDate.getMonth(),
            endDate.getDate() - _arg.daysAgo
          );

      const optinQuery = await fetchWithBQ(
        `optin-count/counts/${_arg.campaignUUID}/?placement=${
          _arg.placementUUID
        }&start_date=${startDate.toISOString()}&end_date=${endDate.toISOString()}`
      );

      const leadQuery = await fetchWithBQ(
        `lead-count/counts/${_arg.campaignUUID}/?placement=${
          _arg.placementUUID
        }&start_date=${startDate.toISOString()}&end_date=${endDate.toISOString()}`
      );

      // if (optinQuery.error) return { error: optinQuery.error };
      // if (leadQuery.error) return { error: leadQuery.error };
      return {
        data: { optins: optinQuery.data.count, leads: leadQuery.data.count },
      };
    },
  }),

  getCampaignAssociations: builder.query({
    query: (options) => ({
      url: `placements/campaign-associations/${options.UUID}?type=all`,
    }),
    transformResponse: (response) => {
      return response.associations;
    },
    providesTags: (result) => {
      return result
        ? [
            ...result.map(({ campaignUUID }) => ({
              type: "Association",
              id: campaignUUID,
            })),
            "Association",
          ]
        : ["Association"];
    },
  }),

  updateCampaignAssociations: builder.mutation({
    query: (body) => ({
      url: `placements/campaign-associations/`,
      method: "POST",
      body,
    }),
    invalidatesTags: ["Association"],
  }),

  getCampaignQuestions: builder.query({
    query: (options) => ({
      url: `questions/questions/${options.UUID}/?byCampaign=true`,
    }),
    transformResponse: (response) => {
      return response.records;
    },
    // Assign tags to response for automated refetching:
    providesTags: (result, error) =>
      result
        ? [
            ...result.map(({ UUID }) => {
              return { type: "Question", id: UUID }; //
            }),
            "Question",
          ]
        : ["Question"],
  }),

  addCampaignQuestion: builder.mutation({
    query: (body) => ({
      url: `questions/questions/`,
      method: "POST",
      body,
    }),
    invalidatesTags: ["Question"],
  }),

  updateCampaignQuestion: builder.mutation({
    query: (body) => ({
      url: `questions/questions/${body.UUID}`,
      method: "PUT",
      body: body.question,
    }),
    invalidatesTags: (result, error, arg) => {
      return [{ type: "Question", id: arg.UUID }]; // Trigger auto refetch by invalidating tag of updated question.
    },
  }),

  removeCampaignQuestion: builder.mutation({
    query: (body) => ({
      url: `questions/questions/${body.UUID}`,
      method: "DELETE",
      body,
    }),
    invalidatesTags: (result, error, arg) => [
      { type: "Question", id: arg.UUID },
    ],
  }),

  reorderCampaignQuestions: builder.mutation({
    query: (body) => ({
      url: `questions/reorder-questions/${body.campaignUuid}`,
      method: "PUT",
      body: { records: body.records },
    }),
    invalidatesTags: ["Question"],
  }),

  testPrePing: builder.mutation({
    query: (body) => ({
      url: `preping/pre-ping/?test=1`,
      method: "POST",
      body,
    }),
    // transformResponse: (response) => response.message,
  }),

  previewPrePing: builder.mutation({
    query: (body) => ({
      url: `preping/preview`,
      method: "POST",
      body,
    }),
    // transformResponse: (response) => response.message,
  }),

  getCampaignCreative: builder.query({
    query: (options) => ({
      url: `creatives/creatives/${options.UUID}`,
    }),

    providesTags: (result) =>
      result
        ? [{ type: "Creative", id: result.uuid }, "Creative"]
        : ["Creative"],
  }),

  getAllCampaignCreativePercentages: builder.query({
    queryFn: async (arg, queryApi, options, fetchWithBQ) => {
      const results = await Promise.all(
        arg.UUIDs.map((uuid) => {
          return fetchWithBQ(`creatives/creatives/${uuid}`);
        })
      );
      if (results.some((res) => res?.error)) {
        return { error: results.find((res) => res.error)?.error };
      }
      return {
        data: results.map((res) => {
          return {
            uuid: res?.data?.uuid,
            percentageToDisplay: res?.data?.percentageToDisplay,
          };
        }),
      };
    },

    providesTags: (result) =>
      result
        ? [{ type: "Creative", id: result.uuid }, "Creative"]
        : ["Creative"],
  }),

  getCampaignCreativePreview: builder.mutation({
    query: (body) => ({
      url: `creatives/testcreative/`,
      method: "POST",
      body,
    }),
  }),

  updateCreative: builder.mutation({
    query: (body) => ({
      url: `creatives/creatives/${body.uuid}`,
      method: "PUT",
      body,
    }),
    invalidatesTags: (result, error, arg) => {
      return [{ type: "Creative", id: arg.uuid }];
    },
  }),

  newCreative: builder.mutation({
    query: (body) => ({
      url: `creatives/creatives/`,
      method: "POST",
      body,
    }),
    invalidatesTags: (result, error, arg) => {
      return ["Creative"];
    },
  }),

  removeCreative: builder.mutation({
    query: (options) => ({
      url: `creatives/creatives/${options.UUID}`,
      method: "DELETE",
    }),
    invalidatesTags: (result, error, arg) => {
      return [{ type: "Creative", id: arg.UUID }];
    },
  }),

  getCreativeCriteria: builder.query({
    query: (options) => {
      return {
        url: `criteria-filtering/creative-criteria/?creative_uuid=${options.UUID}`,
      };
    },

    transformResponse: (response) => {
      return response.criteriaFilter;
    },

    providesTags: (result) => {
      return result
        ? [{ type: "Criteria", id: result.creativeUuid }, "Criteria"]
        : ["Criteria"];
    },
  }),

  updateCreativeCriteria: builder.mutation({
    query: (body) => {
      return {
        url: `criteria-filtering/creative-criteria/?creative_uuid=${body.creative_uuid}`,
        method: "POST",
        body: body,
      };
    },

    invalidatesTags: (result, error, arg) => {
      return arg.creative_uuid
        ? [{ type: "Criteria", id: arg.creative_uuid }]
        : ["Criteria"];
    },
  }),

  getCampaignGroups: builder.query({
    query: (options) => ({
      url: `campaigns/campaign-groups/${options.UUID}?limit=500&offset=0`,
    }),
    providesTags: (result) => {
      return result
        ? [{ type: "CampaignGroup", id: result.uuid }, "CampaignGroup"]
        : ["CampaignGroup"];
    },
  }),

  updateCampaignGrouping: builder.mutation({
    query: (body) => ({
      url: `campaigns/campaign-groups/`,
      method: "POST",
      body,
    }),
    invalidatesTags: (result, error, arg) => {
      return [{ type: "CampaignGroup", id: arg.uuid }];
    },
  }),

  removeCampaignFromGroup: builder.mutation({
    query: ({ UUID }) => ({
      url: `campaigns/campaign-groups/${UUID}`,
      method: "DELETE",
      body: { UUID: UUID },
    }),
    invalidatesTags: (result, error, arg) => {
      return [{ type: "CampaignGroup", id: arg.groupUUID }];
    },
  }),
});
