export const reportEndpoints = (builder) => ({
  // Reports Start
  loadAdqPerformanceReport: builder.mutation({
    query: ({ format, slug, ...body }) => {
      // let today = new Date().toLocaleString("en-US", {
      //   year: "numeric",
      //   month: "2-digit",
      //   day: "2-digit",
      // });
      return {
        url:
          format && slug
            ? `reporting/adq-performance/?format=${format}&slug=${slug}`
            : "reporting/adq-performance/",
        method: "POST",
        body: format && slug ? {} : body,
      };
    },
  }),

  loadValidationReport: builder.mutation({
    query: ({ format, slug, limit, ...body }) => {
      return {
        url:
          format && slug
            ? `reporting/single-validation-by-message/?format=${format}&slug=${slug}`
            : `reporting/single-validation-by-message/`,
        method: "POST",
        body: format && slug ? {} : { ...body, groupByDate: true },
      };
    },
  }),

  loadBrokeredLeadViewer: builder.mutation({
    query: ({ format = "json", slug, ...body }) => {
      return {
        url:
          format && slug
            ? `reporting/brokered-lead-viewer/?format=${format}&slug=${slug}`
            : `reporting/brokered-lead-viewer/?format=${format}`,
        method: "POST",
        body: format && slug ? {} : body,
      };
    },
    transformResponse: (response) => {
      return {
        report: response.report.leads.map((lead) => {
          let leadData = {
            status: lead.status,
            email: lead.sessionDetails.session.email,
            sessionUUID: lead.sessionDetails.session.sessionUUID,
            createdAt: lead.sessionDetails.session.createdAt,
            softwareVersion: lead.sessionDetails.session.softwareVersion,
            userData: {
              email: lead.sessionDetails.session.email,
              name: `${lead.sessionDetails.session.firstName} ${lead.sessionDetails.session.lastName}`,
              address1: lead.sessionDetails.session.address1,
              address2: lead.sessionDetails.session.address2,
              city: lead.sessionDetails.session.city,
              state: lead.sessionDetails.session.state,
              zipCode: lead.sessionDetails.session.zipcode,
              dob: lead.sessionDetails.session.DOB,
              gender: lead.sessionDetails.session.gender,
              userIP: lead.sessionDetails.session.userIP,
            },
            device: {
              deviceUserAgent: lead.sessionDetails.session.deviceUserAgent,
              deviceType: lead.sessionDetails.session.deviceType,
              deviceMobileType: lead.sessionDetails.session.deviceMobileType,
              deviceOsName: lead.sessionDetails.session.deviceOsName,
              deviceOsVersion: lead.sessionDetails.session.deviceOsVersion,
              deviceBrowserName: lead.sessionDetails.session.deviceBrowserName,
              deviceBrowserVersion:
                lead.sessionDetails.session.deviceBrowserVersion,
            },

            requestString: lead.requestString,
            responseString: lead.responseString,
            validationResults: {
              text: !lead.validationResults.length
                ? ""
                : lead.validationResults[0].message,
              subtext: !lead.validationResults.length
                ? ""
                : lead.validationResults[0].code,
            },
            misc: {
              pubKey: lead.pubKey,
              pubTime: "",
              createdAt: lead.sessionDetails.session.createdAt,
              isNewPdle: lead.isNewPdle ? "true" : "false",
            },
            placement: {
              text: lead.placementName,
              subtext: lead.placementUUID,
            },
            campaign: { text: lead.campaignName, subtext: lead.campaignUUID },
          };
          return leadData;
        }),
        request: response.request,
      };
    },
  }),

  loadBrokeredFailedLeads: builder.mutation({
    query: ({ format, slug, ...body }) => {
      return {
        url:
          format && slug
            ? `reporting/brokered-failed-leads/?format=${format}&slug=${slug}`
            : "reporting/brokered-failed-leads/",
        method: "POST",
        body: format && slug ? {} : body,
      };
    },
    transformResponse: (response) => {
      return {
        placementStats: response.report.placementStats.records.map((lead) => {
          return lead;
        }),

        placementDetails: response.report.placementDetails.records.map(
          (lead) => {
            return {
              placement: {
                text: lead.placementName,
                subtext: lead.placementUUID,
              },
              errors: lead.errors.map((error) => {
                return {
                  error: {
                    text: error.errorMessage,
                    subtext: error.errorCode,
                  },
                  id: lead.placementUUID,
                  count: error.errorCount,
                };
              }),

              failedLeadCount: lead.failedLeadCount,
            };
          }
        ),
        request: response.request,
      };
    },
  }),

  loadSessionViewer: builder.mutation({
    query: ({ format, slug, ...body }) => {
      return {
        url:
          format && slug
            ? `reporting/session-lookup/?format=${format}&slug=${slug}`
            : "reporting/session-lookup/",
        method: "POST",
        body: format && slug ? {} : body,
      };
    },
  }),

  getSessionDetails: builder.mutation({
    query: ({ ...body }) => {
      return {
        url: "reporting/session-details/",
        method: "POST",
        body: body,
      };
    },
    transformResponse: (response) => {
      return {
        session: [response.report.sessionDetailsRecord.session],
        prevalidations: response.report.sessionDetailsRecord.prevalidations
          .length
          ? response.report.sessionDetailsRecord.prevalidations[0]
          : {},

        postvalidations: response.report.sessionDetailsRecord.postvalidations
          .length
          ? response.report.sessionDetailsRecord.postvalidations[0]
          : {},
      };
    },
  }),

  loadTruIntentReport: builder.mutation({
    query: ({ format, slug, ...body }) => {
      return {
        url:
          format && slug
            ? `reporting/tru-intent/?format=${format}&slug=${slug}`
            : "reporting/tru-intent/",
        method: "POST",
        body: format && slug ? {} : body,
      };
    },
    transformResponse: (response) => {
      return {
        placementData: {
          data: response.report.placementSection.records.map((placement) => {
            return {
              placementType: placement.placementType,
              placement: {
                text: `${placement.placementName} (${placement.placementLegacyCode})`,
                subtext: placement.placementUUID,
              },
              attemptedLeads: placement.attemptedCount,
              leads: placement.acceptedCount,
              rejectedCount: placement.rejectedCount,
              rejectedPercentage: placement.rejectedPercentage,
              rtRejectedCount: placement.rtRejectedCount,
              rtRejectedPercentage: placement.rtRejectedPercentage,
              estimatedRevenue: placement.estimatedRevenue,
            };
          }),
          totals: {
            attemptedLeads:
              response.report.placementSection.totals.attemptedCount,
            leads: response.report.placementSection.totals.acceptedCount,
            rejectedCount:
              response.report.placementSection.totals.rejectedCount,
            rejectedPercentage:
              response.report.placementSection.totals.rejectedPercentage,
            rtRejectedCount:
              response.report.placementSection.totals.rtRejectedCount,
            rtRejectedPercentage:
              response.report.placementSection.totals.rtRejectedPercentage,
            estimatedRevenue:
              response.report.placementSection.totals.estimatedRevenue,
          },
        },
        campaignData: {
          data: response.report.campaignSection.records.map((campaign) => {
            return {
              campaign: {
                text: campaign.campaignName,
                subtext: campaign.campaignUUID,
              },
              campaignCurrentRate: campaign.campaignCurrentRate,
              campaignRateList: campaign.campaignRateList,
              attemptedLeads: campaign.attemptedCount,
              leads: campaign.acceptedCount,
              rejectedCount: campaign.rejectedCount,
              rejectedPercentage: campaign.rejectedPercentage,
              rtRejectedCount: campaign.rtRejectedCount,
              rtRejectedPercentage: campaign.rtRejectedPercentage,
              estimatedRevenue: campaign.estimatedRevenue,
            };
          }),
          totals: {
            attemptedLeads:
              response.report.campaignSection.totals.attemptedCount,
            leads: response.report.campaignSection.totals.acceptedCount,
            rejectedCount: response.report.campaignSection.totals.rejectedCount,
            rejectedPercentage:
              response.report.campaignSection.totals.rejectedPercentage,
            rtRejectedCount:
              response.report.campaignSection.totals.rtRejectedCount,
            rtRejectedPercentage:
              response.report.campaignSection.totals.rtRejectedPercentage,
            estimatedRevenue:
              response.report.campaignSection.totals.estimatedRevenue,
          },
        },
        request: response.request,
      };
    },
  }),

  loadTruIntentFailedLeads: builder.mutation({
    query: ({ format, slug, ...body }) => {
      return {
        url:
          format && slug
            ? `reporting/tru-intent-rejected-leads/?format=${format}&slug=${slug}`
            : "reporting/tru-intent-rejected-leads/",
        method: "POST",
        body: format && slug ? {} : body,
      };
    },
    transformResponse: (response) => {
      return {
        leadRejections: response.report.rejectionsSection.records.map(
          (rejection) => {
            return {
              campaign: {
                text: `${rejection.campaignShortCode} - ${rejection.campaignName}`,
                subtext: rejection.campaignUUID,
              },
              placement: {
                text: `${rejection.placementType} -${rejection.placementLegacyCode} - ${rejection.placementName}`,
                subtext: rejection.placementUUID,
              },
              attempts: rejection.attempts.map((attempt) => {
                return {
                  createdAt: attempt.createdAt,
                  optinUUID: attempt.optinUUID,
                  sessionData: [
                    {
                      sessionUUID: attempt.sessionDetails.session.sessionUUID,
                      createdAt: attempt.sessionDetails.session.createdAt,
                      softwareVersion:
                        attempt.sessionDetails.session.softwareVersion,
                      userData: {
                        multiple: true,
                        data: {
                          email: attempt.sessionDetails.session.email,
                          name: `${attempt.sessionDetails.session.firstName} ${attempt.sessionDetails.session.lastName}`,
                          address1: attempt.sessionDetails.session.address1,
                          address2: attempt.sessionDetails.session.address2,
                          city: attempt.sessionDetails.session.city,
                          state: attempt.sessionDetails.session.state,
                          zipCode: attempt.sessionDetails.session.zipcode,
                          dob: attempt.sessionDetails.session.DOB,
                          gender: attempt.sessionDetails.session.gender,
                          userIP: attempt.sessionDetails.session.userIP,
                        },
                      },
                      device: {
                        multiple: true,
                        data: {
                          // move bulk to api slice
                          deviceUserAgent:
                            attempt.sessionDetails.session.deviceUserAgent,
                          deviceType: attempt.sessionDetails.session.deviceType,
                          deviceMobileType:
                            attempt.sessionDetails.session.deviceMobileType,
                          deviceOsName:
                            attempt.sessionDetails.session.deviceOsName,
                          deviceOsVersion:
                            attempt.sessionDetails.session.deviceOsVersion,
                          deviceBrowserName:
                            attempt.sessionDetails.session.deviceBrowserName,
                          deviceBrowserVersion:
                            attempt.sessionDetails.session.deviceBrowserVersion,
                        },
                      },
                    },
                  ],
                  validations: {
                    multiple: true,
                    data: attempt.validations
                      .map((v, idx) =>
                        v.message
                          ? {
                              [v.serviceName]: v.message,
                            }
                          : {}
                      )
                      .reduce((acc, val) => ({ ...acc, ...val }), {}),
                  },
                };
              }),
              attemptsCount: rejection.attempts.length,
              validationsSummary: {
                multiple: true,
                data: rejection.attempts
                  .flatMap((attempt) =>
                    attempt.validations.map((v, idx) =>
                      v.message
                        ? {
                            [v.serviceName]: v.message,
                          }
                        : {}
                    )
                  )
                  .reduce((acc, val) => ({ ...acc, ...val }), {}),
              },
            };
          }
        ),
        titleData: {
          title:
            response?.request?.placementUUIDs.length > 0
              ? `Placement: ${response?.report.rejectionsSection.records[0]?.placementName} (${response?.request?.placementUUIDs[0]})`
              : response?.request?.campaignUUIDs.length > 0
              ? `Campaign: ${response?.report.rejectionsSection.records[0]?.campaignName} (${response?.request?.campaignUUIDs[0]})`
              : "N/A",
          startDate: response.request.startDate,
          endDate: response.request.endDate,
          customDataFilter: response.request.customDataFilter || "None",
        },
        request: response.request,
      };
    },
  }),

  loadTruIntentFailedRts: builder.mutation({
    query: ({ format, slug, ...body }) => {
      return {
        url:
          format && slug
            ? `reporting/tru-intent-rejected-rts/?format=${format}&slug=${slug}`
            : "reporting/tru-intent-rejected-rts/",
        method: "POST",
        body: format && slug ? {} : body,
      };
    },
    transformResponse: (response) => {
      return {
        rtsRejections: response.report.rtsSection.records.map((rejection) => {
          return {
            lead: rejection.leadUUID,
            campaign: {
              text: `${rejection.campaignShortCode} - ${rejection.placementLegacyCode} - ${rejection.campaignName}`,
              subtext: rejection.campaignUUID,
            },
            placement: {
              text: `${rejection.placementType} - ${rejection.placementName}`,
              subtext: rejection.placementUUID,
            },
            attemptsCount: rejection.attempts.length,
            attempts: rejection.attempts.map((attempt) => {
              return {
                createdAt: attempt.createdAt,
                optinUUID: attempt.optinUUID,
                sessionData: [
                  {
                    sessionUUID: attempt.sessionDetails.session.sessionUUID,
                    createdAt: attempt.sessionDetails.session.createdAt,
                    softwareVersion:
                      attempt.sessionDetails.session.softwareVersion,
                    userData: {
                      multiple: true,
                      data: {
                        email: attempt.sessionDetails.session.email,
                        name: `${attempt.sessionDetails.session.firstName} ${attempt.sessionDetails.session.lastName}`,
                        address1: attempt.sessionDetails.session.address1,
                        address2: attempt.sessionDetails.session.address2,
                        city: attempt.sessionDetails.session.city,
                        state: attempt.sessionDetails.session.state,
                        zipCode: attempt.sessionDetails.session.zipcode,
                        dob: attempt.sessionDetails.session.DOB,
                        gender: attempt.sessionDetails.session.gender,
                        userIP: attempt.sessionDetails.session.userIP,
                      },
                    },
                    device: {
                      multiple: true,
                      data: {
                        // move bulk to api slice
                        deviceUserAgent:
                          attempt.sessionDetails.session.deviceUserAgent,
                        deviceType: attempt.sessionDetails.session.deviceType,
                        deviceMobileType:
                          attempt.sessionDetails.session.deviceMobileType,
                        deviceOsName:
                          attempt.sessionDetails.session.deviceOsName,
                        deviceOsVersion:
                          attempt.sessionDetails.session.deviceOsVersion,
                        deviceBrowserName:
                          attempt.sessionDetails.session.deviceBrowserName,
                        deviceBrowserVersion:
                          attempt.sessionDetails.session.deviceBrowserVersion,
                      },
                    },
                  },
                ],
                validationCode: attempt.validationCode,
              };
            }),
            validationCode: rejection.validationCode,
          };
        }),
        titleData: {
          title:
            response?.request?.placementUUIDs.length > 0
              ? `Placement: ${response?.report.rtsSection.records[0]?.placementName} (${response?.request?.placementUUIDs[0]})`
              : response?.request?.campaignUUIDs.length > 0
              ? `Campaign: ${response?.report.rtsSection.records[0]?.campaignName} (${response?.request?.campaignUUIDs[0]})`
              : "N/A",
          startDate: response.request.startDate,
          endDate: response.request.endDate,
          customDataFilter: response.request.customDataFilter || "None",
        },
        request: response.request,
      };
    },
  }),

  getPubPrevalidationByDate: builder.mutation({
    query: ({ format, slug, limit, ...body }) => {
      return {
        url:
          format && slug
            ? `reporting/pub-prevalidation-by-date/?format=${format}&slug=${slug}`
            : "reporting/pub-prevalidation-by-date/",
        method: "POST",
        body: format && slug ? {} : body,
      };
    },
    transformResponse: (response) => {
      // Re-structuring dynamic column values for easier access:
      const dynamicTableRows =
        // Using pivot dimension date values:
        response.Report.Components[0].PivotSettings.PivotDimensionValues.reduce(
          (accumulator, value, idx) => {
            return {
              ...accumulator,
              [idx]: response.Report.Components[0].RowCluster.map((row) => {
                // Return slices of every 3 elements
                // (they're all dumped in the same array, should probably place every 3 in a new index of RowValueSets)
                return {
                  date: value,
                  placement: {
                    text: row.RowKeys[0].DisplayKey,
                    subtext: row.RowKeys[1].DisplayKey,
                  },
                  campaign: {
                    text: row.RowKeys[2].DisplayKey,
                    subtext: row.RowKeys[4].DisplayKey,
                  },

                  values: row.RowValueSets[0].RowValues.slice(
                    idx * 3,
                    idx * 3 + 3
                  ),
                };
              }),
            };
          },
          {}
        );

      return {
        report: {
          data: response.Report.Components[0].RowCluster.map((row, idx) => {
            return {
              placement: row.RowKeys[0].DisplayKey || "",
              campaign: row.RowKeys[2].DisplayKey || "",
              shortCode: row.RowKeys[3].DisplayKey || "",
              dynamicRow: Object.entries(dynamicTableRows).map((column) => {
                return Object.assign({}, column[1][idx]);
              }),
            };
          }),
          dynamicRows: Object.values(dynamicTableRows).flat(),
        },
        request: response.Request,
      };
    },
  }),

  getPubPrevalidationByMessage: builder.mutation({
    query: ({ format, slug, limit, ...body }) => {
      return {
        url:
          format && slug
            ? `reporting/pub-prevalidation-by-message/?format=${format}&slug=${slug}`
            : "reporting/pub-prevalidation-by-message/",
        method: "POST",
        body: format && slug ? {} : body,
      };
    },
    transformResponse: (response) => {
      return {
        report: {
          data: response.Report.Components[0].RowCluster.map((row, idx) => {
            return {
              placement: row.RowKeys[0].DisplayKey || "",
              campaign: row.RowKeys[2].DisplayKey || "",
              shortCode: row.RowKeys[3].DisplayKey || "",
              leadAlreadyExists: row.RowValueSets[0].RowValues[0].DataValue,
              leadExistsInClientSystem:
                row.RowValueSets[0].RowValues[1].DataValue,
            };
          }),
        },
        request: response.Request,
      };
    },
  }),

  loadActivityLog: builder.mutation({
    query: ({ format, slug, limit, ...body }) => {
      return {
        url:
          format && slug
            ? `reporting/activity-log/?format=${format}&slug=${slug}`
            : "reporting/activity-log/?format=json",
        method: "POST",
        body: format ? {} : body,
      };
    },
  }),

  loadCampaignQuickStatus: builder.mutation({
    query: ({ format, slug, ...body }) => {
      return {
        url:
          format && slug
            ? `reporting/campaign-quick-status/?format=${format}&slug=${slug}`
            : "reporting/campaign-quick-status/",
        method: "POST",
        body: body,
      };
    },
  }),

  loadInternalBudget: builder.mutation({
    query: ({ format, slug, ...body }) => {
      return {
        url:
          format && slug
            ? `reporting/internal-budget/?format=${format}&slug=${slug}`
            : "reporting/internal-budget/",
        method: "POST",
        body: body,
      };
    },
  }),

  loadBuySheet: builder.mutation({
    query: ({ format, slug, ...body }) => {
      return {
        url:
          format && slug
            ? `reporting/buy-sheet/?format=${format}&slug=${slug}`
            : "reporting/buy-sheet/",
        method: "POST",
        body: body,
      };
    },

    transformResponse: (response) => {
      return {
        report: response.Report.Associations,
        request: response.Request,
      };
    },

    providesTags: (result, error, arg) => {
      return result
        ? [
            ...result.report.map((asoc) => {
              return {
                //   type: "BuySheetAssoc",
                //   id: v.service_name,
              };
            }),
            "BuySheetAssoc",
          ]
        : ["BuySheetAssoc"];
    },
  }),

  loadDeliverableViewer: builder.query({
    query: ({ limit, ...body }) => {
      return {
        url: `deliverable-manager/deliverable-viewer/?limit=${limit}&campaign_uuid=${body.campaignUUIDs}&placement_uuid=${body.placementUUIDs}&deliverable_status=${body.deliverableStatus}&start_date=${body.startDate}&end_date=${body.endDate}&timezone=America/New_York`,
      };
    },

    transformResponse: (response) => {
      return {
        records: response.data.records,
        campaignAggregates: response.campaignAggregates.records,
      };
    },

    providesTags: (result, error, arg) => {
      return result
        ? [
            ...result.records.map((del) => {
              return {
                type: "Deliverable",
                id: del.deliverable.UUID,
              };
            }),
            "Deliverable",
          ]
        : ["Deliverable"];
    },
  }),

  updateDeliverableViewerStatus: builder.mutation({
    query: ({ ...body }) => {
      return {
        url: "deliverable-manager/deliverables/update-status/",
        method: "PUT",
        body: body,
      };
    },

    invalidatesTags: (result, error, arg) => {
      return [{ type: "Deliverable", id: arg.deliverableUUID }];
    },
  }),

  loadQuarantine: builder.query({
    query: (body) => {
      return {
        url: `optin-reviewer/reviewables/report`,
        method: "POST",
        body: body,
      };
    },

    providesTags: (result, error, arg) => {
      return ["Reviewable"];
    },
  }),

  // Reports Start
  loadCampaignAnalyticsReport: builder.mutation({
    query: ({ format, slug, ...body }) => {
      // let today = new Date().toLocaleString("en-US", {
      //   year: "numeric",
      //   month: "2-digit",
      //   day: "2-digit",
      // });
      return {
        url:
          format && slug
            ? `reporting/question-answer-breakdown/?format=${format}&slug=${slug}`
            : "reporting/question-answer-breakdown/",
        method: "POST",
        body: format && slug ? {} : body,
      };
    },
  }),

  loadAddressValidationBreakdownReport: builder.mutation({
    query: ({ format, slug, ...body }) => {
      // let today = new Date().toLocaleString("en-US", {
      //   year: "numeric",
      //   month: "2-digit",
      //   day: "2-digit",
      // });
      return {
        url:
          format && slug
            ? `reporting/address-validation-breakdown/?format=${format}&slug=${slug}`
            : "reporting/address-validation-breakdown/",
        method: "POST",
        body: format && slug ? {} : body,
      };
    },

    transformResponse: (response) => {
      const res = [
        {
          status: "Correct",
          data: response.report.sum_totals[0].correct,
          total: response.report.sum_totals[0].correct_total,
          percent: response.report.sum_totals[0].correct_percent,
        },
        {
          status: "Incorrect",
          data: response.report.sum_totals[0].incorrect,
          total: response.report.sum_totals[0].incorrect_total,
          percent: response.report.sum_totals[0].incorrect_percent,
        },
        {
          status: "Semi Correct",
          data: response.report.sum_totals[0].semi_correct,
        },
        {
          status: "Semi Correct, Auto correctable",
          data: response.report.sum_totals[0].semi_correct_auto_correctable,
        },
        {
          status: "Unprocessed",
          data: response.report.sum_totals[0].unprocessed,
        },
        {
          status: "Melissa Error",
          data: response.report.sum_totals[0].melissa_error,
        },
      ];
      return {
        report: res,
        campaigns: response.report.campaigns.map((c) => {
          return {
            campaignName: c.campaignName,
            campaignUUID: c.campaign_uuid,
            ...c.total,
          };
        }),
        placements: response.report.placements.map((c) => {
          return {
            placementName: c.placementName,
            placementUUID: c.placement_uuid,
            ...c.total,
          };
        }),
        request: response.request,
      };
    },
  }),

  updateBulkReviewStatus: builder.mutation({
    query: ({ ...body }) => {
      return {
        url: `optin-reviewer/reviewables/bulkReviewStatusUpdate`,
        method: "PUT",
        body: body,
      };
    },

    invalidatesTags: (result, error, arg) => {
      return ["Reviewable"];
    },
  }),
});
