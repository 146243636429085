import { useEffect } from "react";
import { useSelector } from "react-redux";
// import { setError } from "../../features/auth/authSlice";

const ErrorBoundary = ({ children }) => {
  // const dispatch = useDispatch();
  const user = useSelector((state) => {
    return state.auth.user;
  });
  useEffect(() => {
    const handleError = (message, source, lineno, colno, error) => {
      // dispatch(setError(`${message} at ${lineno}:${colno}`));
      fetch("https://manager-edge-d.adquire.com/error/", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          message: `${message} at ${lineno}:${colno}`,
          timestamp: new Date().toISOString(),
          source: source || "unknown",
          url: window.location.href,
          user_email: user?.profile.email,
        }),
      }).catch((err) => console.error(err));

      return true;
    };

    const handleRejection = (event) => {
      // dispatch(setError(`Unhandled Promise Rejection: ${event.reason}`));
      fetch("https://manager-edge-d.adquire.com/error/", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          message: `Unhandled Promise Rejection: ${event.reason}`,
          timestamp: new Date().toISOString(),
          source: "unknown",
          url: window.location.href,
          user_email: user?.profile.email,
        }),
      }).catch((err) => console.error(err));
      event.preventDefault();
    };

    window.onerror = handleError;
    window.addEventListener("unhandledrejection", handleRejection);

    return () => {
      window.onerror = null;
      window.removeEventListener("unhandledrejection", handleRejection);
    };
  }, [user]);

  return children;
};

export default ErrorBoundary;
