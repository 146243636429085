export const placementEndpoints = (builder) => ({
  getPlacementGroups: builder.query({
    query: (options) => ({
      url: `placements/placement-groups/${options.UUID}?limit=500&offset=0`,
    }),
    providesTags: (result) => {
      return result
        ? [{ type: "PlacementGroup", id: result.uuid }, "PlacementGroup"]
        : ["PlacementGroup"];
    },
  }),

  updatePlacementGrouping: builder.mutation({
    query: (body) => ({
      url: `placements/placement-groups/`,
      method: "POST",
      body,
    }),
    invalidatesTags: (result, error, arg) => {
      return [{ type: "PlacementGroup", id: arg.uuid }];
    },
  }),

  removePlacementFromGroup: builder.mutation({
    query: ({ UUID }) => ({
      url: `placements/placement-groups/${UUID}`,
      method: "DELETE",
      body: { UUID: UUID },
    }),
    invalidatesTags: (result, error, arg) => {
      return [{ type: "PlacementGroup", id: arg.groupUUID }];
    },
  }),

  getPlacements: builder.query({
    query: (
      options = {
        thin: "yes",
        includeArchived: "no",
        includeLegacy: "no",
      }
    ) => ({
      url: `placements/placements/?thin=${options.thin}&include_archived=${options.includeArchived}&include_legacy_archived=${options.includeLegacy}`,
    }),
    transformResponse: (response) => response.records,
    providesTags: (result) => {
      return result
        ? [
            ...result.map(({ UUID }) => ({ type: "Placement", id: UUID })),
            "Placement",
          ]
        : ["Placement"];
    },
  }),

  getPlacementById: builder.query({
    query: (options) => ({
      url: `placements/placements/${options.UUID}/?thin=no`,
    }),
    providesTags: (result, error, arg) => {
      return result ? [{ type: "Placement", id: result.UUID }] : ["Placement"];
    },
  }),

  updatePlacementById: builder.mutation({
    query: (body) => {
      return {
        url: `placements/placements/${body.UUID}`,
        method: "PUT",
        body,
      };
    },
    invalidatesTags: (result, error, arg) => {
      return [{ type: "Placement", id: arg.UUID }];
    },
  }),

  newPlacement: builder.mutation({
    query: (body) => {
      return {
        url: `placements/placements/`,
        method: "POST",
        body,
      };
    },
    invalidatesTags: (result, error, arg) => {
      return ["Placement"];
    },
  }),

  getRankers: builder.query({
    query: (options) => ({
      url: `campaign-sorting/rankers/${options.UUID}`,
    }),
  }),

  getLeadAcceptance: builder.query({
    query: (options) => ({
      url: `campaign-sorting/associated-lead-acceptance?placement_uuid=${options.UUID}`,
    }),
    transformResponse: (response) => response.records,
  }),

  updateLeadAcceptance: builder.mutation({
    query: (body) => {
      return {
        url: `campaign-sorting/batch-placement-associated-configurations?placement_uuid=${body.UUID}`,
        method: "POST",
        body: body.data,
      };
    },
  }),

  getPlacementAddressValidation: builder.query({
    query: (options) => {
      return {
        url: `address-validation/batch-placement-associated-configurations?placement_uuid=${options.UUID}`,
      };
    },
    transformResponse: (response) => response.records,
  }),

  updatePlacementAddressValidation: builder.mutation({
    query: (body) => {
      return {
        url: `address-validation/batch-placement-associated-configurations?placement_uuid=${body.UUID}`,
        method: "POST",
        body: body.data,
      };
    },
  }),

  getPlacementCriteria: builder.query({
    query: (options) => {
      return {
        url: `criteria-filtering/associated-criteria?placement_uuid=${options.UUID}`,
      };
    },

    transformResponse: (response) => {
      return response.records;
    },

    providesTags: (result) => {
      return result.length
        ? [{ type: "Criteria", id: result[0].campaignUuid }, "Criteria"]
        : ["Criteria"];
    },
  }),

  updatePlacementCriteria: builder.mutation({
    query: (body) => {
      return {
        url: `criteria-filtering/associated-criteria/?placement_uuid=${body.UUID}`,
        method: "POST",
        body: body.data,
      };
    },

    invalidatesTags: (result, error, arg) => {
      return arg.campaignUUID
        ? [{ type: "Criteria", id: arg.campaignUUID }]
        : ["Criteria"];
    },
  }),

  removePlacementCriteria: builder.mutation({
    query: (body) => {
      return {
        url: `criteria-filtering/associated-criteria`,
        method: "DELETE",
        body: body.data,
      };
    },

    invalidatesTags: (result, error, arg) => {
      return [{ type: "Criteria", id: arg.campaignUUID }];
    },
  }),
});
