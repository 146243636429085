import { useCallback, useEffect, useState, useMemo } from "react";
import {
  sortByString,
  sortByBoolean,
  sortByDate,
  sortByNumber,
  sortByField,
} from "../utils/utils";
const usePagination = (
  pageSize,
  data,
  sortOptions,
  customButtonToggle = true
) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [pageData, setPageData] = useState([]);
  // const [totalPages, setTotalPages] = useState();

  const range = (start, end) => {
    let length = end - start + 1;
    /*
  	Create an array of certain length and set the elements within it from
    start value to end value.
  */
    return Array.from({ length }, (_, idx) => idx + start);
  };
  const handlePageChange = (idx) => {
    setCurrentPage(idx);
  };

  // useEffect(() => {
  //   if (Math.ceil(data.length / pageSize) < currentPage) {
  //     handlePageChange(0);
  //   }
  //   setTotalPages(Math.ceil(data.length / pageSize));
  // }, [data, pageSize, setTotalPages, currentPage]);

  const totalPages = useMemo(() => {
    const totalPageCount = Math.ceil(data.length / pageSize);

    // On data change (filtered by search) reset to first page.
    if (Math.ceil(data.length / pageSize) <= currentPage) {
      handlePageChange(0);
    }
    let siblingCount = 2;
    const totalPageNumbers = siblingCount + 5;

    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    const leftIdx = Math.max(currentPage - 1, 1);
    const rightIdx = Math.min(currentPage + 1, totalPageCount);

    const showLeftDots = leftIdx > siblingCount;
    const showRightDots = rightIdx < totalPageCount - siblingCount;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    if (!showLeftDots && showRightDots) {
      let leftItemCount = 3 + siblingCount * 1;
      let leftRange = range(1, leftItemCount);

      return [...leftRange, -1, totalPageCount];
    }

    if (showLeftDots && !showRightDots) {
      let rightItemCount = 3 + siblingCount * 1;
      let rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      );
      return [firstPageIndex, -1, ...rightRange];
    }

    if (showLeftDots && showRightDots) {
      let middleRange = range(leftIdx, rightIdx);
      return [firstPageIndex, -1, ...middleRange, -1, lastPageIndex];
    }
  }, [data, pageSize, currentPage]);

  const handleSort = useCallback(
    ({ field, type, order }) => {
      switch (type) {
        case "number":
          return order
            ? sortByNumber([...data], field)
            : sortByNumber([...data], field).reverse();
        case "date":
          return order
            ? sortByDate([...data], field)
            : sortByDate([...data], field).reverse();
        case "bool":
          return order
            ? sortByBoolean([...data], field)
            : sortByBoolean([...data], field).reverse();
        case "field":
          return order
            ? sortByField([...data], customButtonToggle, field)
            : sortByField([...data], customButtonToggle, field).reverse();

        default:
          return order
            ? sortByString([...data], field)
            : sortByString([...data], field).reverse();
      }
    },
    [data, customButtonToggle]
  );

  useEffect(() => {
    setPageData(
      handleSort(sortOptions)?.slice(
        currentPage * pageSize,
        (currentPage + 1) * pageSize
      )
    );
  }, [currentPage, setPageData, pageSize, handleSort, sortOptions]);

  return { totalPages, currentPage, pageData, handlePageChange };
};

export default usePagination;
