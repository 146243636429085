import React, { Suspense, lazy } from "react";

import { Route, Routes, Outlet } from "react-router-dom";
import Loader from "../../common/components/Loader";
import {
  useGetCampaignsQuery,
  useGetPlacementsQuery,
} from "../../app/apiSlice";

const BlockedAreaCodes = lazy(() =>
  import("./BlockedAreaCodes/BlockedAreaCodes")
);
const BlockedEmails = lazy(() => import("./BlockedEmails/BlockedEmails"));
const BlockedDomains = lazy(() => import("./BlockedDomains/BlockedDomains"));
const BadWords = lazy(() => import("./BadWords/BadWords"));
const Tools = lazy(() => import("./Tools/Tools"));

const ValidationManagement = lazy(() =>
  import("./ValidationManagement/ValidationManagement")
);
const ContactManagement = lazy(() =>
  import("./ContactManagement/ContactManagement")
);

const DarwinGroupManagement = lazy(() => import("./DarwinGroups/DarwinGroups"));
const IpLookup = lazy(() => import("./IpLookup/IpLookup"));

const Ops = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={<Loader message="Loading Ops..." />}>
            <OpsLayout />
          </Suspense>
        }
      >
        <Route
          path="tools/"
          element={
            <Suspense fallback={<Loader message="Loading Tools..." />}>
              <Tools />
            </Suspense>
          }
        />

        <Route
          path="bad_words/"
          element={
            <Suspense fallback={<Loader message="Loading Bad Words..." />}>
              <BadWords />
            </Suspense>
          }
        />

        <Route
          path="blocked_domains/"
          element={
            <Suspense
              fallback={<Loader message="Loading Blocked Domains..." />}
            >
              <BlockedDomains />
            </Suspense>
          }
        />
        <Route
          path="blocked_emails/"
          element={
            <Suspense fallback={<Loader message="Loading Blocked Emails..." />}>
              <BlockedEmails />
            </Suspense>
          }
        />
        <Route
          path="blocked_area_codes/"
          element={
            <Suspense
              fallback={<Loader message="Loading Blocked Area Codes..." />}
            >
              <BlockedAreaCodes />
            </Suspense>
          }
        />
        <Route
          path="validation_management/"
          element={
            <Suspense
              fallback={<Loader message="Loading Validation Management..." />}
            >
              <ValidationManagement />
            </Suspense>
          }
        />

        <Route
          path="contact_management/"
          element={
            <Suspense
              fallback={<Loader message="Loading Contact Management..." />}
            >
              <ContactManagement />
            </Suspense>
          }
        />

        <Route
          path="darwin_groups/"
          element={
            <Suspense fallback={<Loader message="Loading Darwin Groups..." />}>
              <DarwinGroupManagement />
            </Suspense>
          }
        />

        <Route
          path="ip_lookup/"
          element={
            <Suspense fallback={<Loader message="Loading Ip Lookup..." />}>
              <IpLookup />
            </Suspense>
          }
        />
      </Route>
    </Routes>
  );
};

const OpsLayout = () => {
  const { data: campaigns, isLoading: campaignsLoading } = useGetCampaignsQuery(
    {
      thin: "yes",
      includeArchived: "no",
      includeLegacy: "no",
    }
  );

  const { data: placements, isLoading: placementsLoading } =
    useGetPlacementsQuery({
      thin: "yes",
      includeArchived: "no",
      includeLegacy: "no",
    });

  if (campaignsLoading || placementsLoading) {
    return <Loader message="Loading Ops..." />;
  }

  return <Outlet context={[campaigns, placements]} />;
};

export default Ops;
