export const opsEndpoints = (builder) => ({
  getCustomers: builder.query({
    query: (options = { limit: 0, offset: 0 }) => ({
      url: `customers/customers/?limit=${options.limit}&offset=${
        options.offset
      }${options.hasOwnProperty("type") ? `&type=${options.type}` : ""}`,
    }),
    transformResponse: (response) =>
      response.records.map((customer) => {
        let { UUID: value, name: text, description, contacts, type } = customer; // Renamed for ease of use in <select>.
        return { value, text, description, type, contacts };
      }),

    providesTags: (result, error, arg) => {
      return result
        ? [
            ...result.map(({ value }) => ({ type: "Customer", id: value })),
            "Customer",
          ]
        : ["Customer"];
    },
  }),

  updateCustomers: builder.mutation({
    query: (body) => ({
      url: `customers/customers/${
        body.hasOwnProperty("UUID") ? body.UUID : ""
      }`,
      method: body.UUID ? "PUT" : "POST",
      body,
    }),

    invalidatesTags: (result, error, arg) => {
      return arg.hasOwnProperty("UUID")
        ? [{ type: "Customer", id: arg.UUID }]
        : ["Customer"];
    },
  }),

  addKeyCloakAccount: builder.mutation({
    query: (body) => ({
      url: `customers/create_account/${body.PersonUUID}`,
      method: "POST",
      body: body,
    }),

    invalidatesTags: (result, error, arg) => {
      return ["ExternalAccount"];
    },
  }),

  getExternalAccounts: builder.query({
    query: ({ personUUID }) => ({
      url: `customers/external_accounts/?persons=${personUUID}`,
    }),

    providesTags: (result, error, arg) => {
      return result.accounts.length
        ? [
            ...result.accounts.map(({ PersonUUID }) => ({
              type: "ExternalAccount",
              id: PersonUUID,
            })),
            "ExternalAccount",
          ]
        : ["ExternalAccount"];
    },
  }),

  getContactNotificationConfigurations: builder.query({
    query: (options) => ({ url: `notification/configuration` }),
    transformResponse: (response) => response.records,
    providesTags: (result, error, arg) => {
      return result
        ? [
            ...result.map(({ id }) => {
              return {
                type: "ContactNotification",
                id: id,
              };
            }),
            "ContactNotification",
          ]
        : ["ContactNotification"];
    },
  }),

  saveContactNotificationConfiguration: builder.mutation({
    query: (body) => ({
      url: `notification/configuration`,
      method: "POST",
      body,
    }),

    invalidatesTags: (result, error, arg) => {
      return arg.hasOwnProperty("placementUuid")
        ? [{ type: "ContactNotification", id: arg.placementUuid }]
        : arg.hasOwnProperty("campaignUuid")
        ? [{ type: "ContactNotification", id: arg.campaignUuid }]
        : ["ContactNotification"];
    },
  }),

  deleteContactNotificationConfiguration: builder.mutation({
    query: (body) => ({
      url: `notification/configuration/${body.id}`,
      method: "DELETE",
    }),
    invalidatesTags: (result, error, arg) => {
      return arg.hasOwnProperty("id")
        ? [{ type: "ContactNotification", id: arg.id }]
        : ["ContactNotification"];
    },
  }),

  // getSalesPerson: builder.query({
  //   query: (options = { limit: 50, offset: 0, type: 3 }) => ({
  //     url: `customers/customers/?limit=${options.limit}&offset=${options.offset}&type=${options.type}`,
  //   }),
  //   transformResponse: (response) =>
  //     response.records.map((salesPerson) => {
  //       let { UUID: value, name: text } = salesPerson;
  //       return { value, text };
  //     }),
  // }),

  uploadEmailSuppression: builder.mutation({
    query: (body) => {
      return {
        url: `lead-deduplication/bulk-hash-leads`,
        method: "POST",
        body,
      };
    },
  }),

  searchZipCodes: builder.mutation({
    query: (body) => {
      return {
        url: `zip-in-radius/zips`,
        method: "POST",
        body,
      };
    },
  }),

  getBadWords: builder.query({
    query: (options) => {
      return {
        url: `bad-words/bad-words`,
      };
    },

    providesTags: (result, error, arg) => {
      return result
        ? [
            ...result.FullMatch.map((word) => ({
              type: "BadWords",
              id: word,
            })),
            ...result.PartialMatch.map((word) => ({
              type: "BadWords",
              id: word,
            })),
            "BadWords",
          ]
        : ["BadWords"];
    },
  }),

  addBadWord: builder.mutation({
    query: (body) => {
      return { url: `bad-words/bad-words`, method: "POST", body };
    },
    invalidatesTags: ["BadWords"],
  }),

  removeBadWord: builder.mutation({
    query: ({ word }) => {
      return {
        url: `bad-words/bad-words/${encodeURIComponent(word)}/`,
        method: "DELETE",
      };
    },
    invalidatesTags: (result, error, arg) => {
      return [{ type: "BadWords", id: arg.word }];
    },
  }),

  testBadWord: builder.query({
    query: ({ word }) => {
      return { url: `bad-words/test-word/?word=${encodeURIComponent(word)}` };
    },
  }),

  getIpLookup: builder.mutation({
    query: ({ ip }) => {
      return {
        url: `ip-lookup/lookup/`,
        method: "POST",
        body: { ip: ip },
      };
    },
  }),

  getAllBlockedEmailDomains: builder.query({
    query: (options) => {
      return {
        url: `blocked-email-domains/domains/?limit=${options.limit}&offset=${options.offset}`,
      };
    },
    providesTags: (result, error, arg) => {
      return result
        ? [
            ...result.Domains.map((d) => ({
              type: "BlockedEmailDomains",
              id: d.name,
            })),

            "BlockedEmailDomains",
          ]
        : ["BlockedEmailDomains"];
    },
  }),

  getBlockedEmails: builder.query({
    query: (options) => {
      return {
        url: `email-blacklist/emails/?limit=${options.limit}&offset=${options.offset}`,
      };
    },
    providesTags: (result, error, arg) => {
      return result
        ? [
            ...result.emails.map((e) => ({
              type: "BlockedEmails",
              id: e.ID,
            })),

            "BlockedEmails",
          ]
        : ["BlockedEmails"];
    },
  }),

  getEmailDomainsToReview: builder.query({
    query: (options) => {
      return {
        url: `blocked-email-domains/domain-hits/?limit=${options.limit}&offset=${options.offset}&count=4&reviewed=false`,
      };
    },

    providesTags: (result, error, arg) => {
      return result
        ? [
            ...result.records.map((d) => ({
              type: "DomainsToReview",
              id: d.name,
            })),

            "DomainsToReview",
          ]
        : ["DomainsToReview"];
    },
  }),

  setEmailDomainAsReviewed: builder.mutation({
    query: (body) => {
      return {
        url: `blocked-email-domains/domain-hits/`,
        method: "PUT",
        body,
      };
    },
    invalidatesTags: (result, error, arg) => {
      return [{ type: "DomainsToReview", id: arg.name }];
    },
  }),

  updateBlockedEmailDomain: builder.mutation({
    query: (body) => {
      return {
        url: `blocked-email-domains/domains/${encodeURIComponent(body.name)}`,
        method: "PUT",
        body,
      };
    },
    invalidatesTags: (result, error, arg) => {
      return [{ type: "BlockedEmailDomains", id: arg.name }];
    },
  }),

  updateBulkBlockedEmailDomain: builder.mutation({
    query: (body) => {
      return {
        url: `blocked-email-domains/batchUpdate/?replace=${body.replace}`,
        method: "POST",
        body: body.data,
      };
    },
    invalidatesTags: ["BlockedEmailDomains"],
  }),

  removeBlockedEmailDomain: builder.mutation({
    query: (body) => {
      return {
        url: `blocked-email-domains/domains/${encodeURIComponent(body.name)}`,
        method: "DELETE",
      };
    },

    invalidatesTags: (result, error, arg) => {
      return [{ type: "BlockedEmailDomains", id: arg.name }];
    },
  }),

  updateBulkBlockedEmails: builder.mutation({
    query: (body) => {
      return {
        url: `email-blacklist/batchUpdate/?replace=${body.replace}}`,
        method: "POST",
        body: body.data,
      };
    },

    invalidatesTags: ["BlockedEmails"],
  }),

  validateBlockedEmails: builder.mutation({
    query: (body) => {
      return {
        url: `email-blacklist/validate`,
        method: "POST",
        body,
      };
    },
  }),

  removeBlockedEmail: builder.mutation({
    query: (body) => {
      return {
        url: `email-blacklist/emails/${encodeURIComponent(body.email)}`,
        method: "DELETE",
      };
    },
    invalidatesTags: ["BlockedEmails"],
  }),

  getBlockedAreaCodes: builder.query({
    query: (options) => {
      return {
        url: `areacode-blacklist/areacodes/?limit=${options.limit}&offset=${options.offset}`,
      };
    },
    providesTags: (result, error, arg) => {
      return result
        ? [
            ...result.AreaCodes.map((e) => ({
              type: "BlockedAreaCodes",
              id: e.areacode,
            })),

            "BlockedAreaCodes",
          ]
        : ["BlockedAreaCodes"];
    },
  }),

  updateBulkBlockedAreaCodes: builder.mutation({
    query: (body) => {
      return {
        url: `areacode-blacklist/batchUpdate?replace=${body.replace}`,
        method: "POST",
        body: body.data,
      };
    },
    invalidatesTags: ["BlockedAreaCodes"],
  }),

  removeBlockedAreaCodes: builder.mutation({
    query: (body) => {
      return {
        url: `areacode-blacklist/areacodes/${body.areacode}`,
        method: "DELETE",
      };
    },
    invalidatesTags: (result, error, arg) => {
      return [{ type: "BlockedAreaCodes", id: arg.areacode }];
    },
  }),

  validateBlockedAreaCode: builder.mutation({
    query: (body) => {
      return {
        url: `areacode-blacklist/validate/`,
        method: "POST",
        body: body,
      };
    },
  }),

  loadDarwinGroups: builder.query({
    query: (options = { limit: 5000, offset: 0 }) => {
      return {
        url: `darwin-rank/groups/?limit=${options.limit}&offset=${options.offset}`,
      };
    },

    transformResponse: (response) => {
      return response.records;
    },

    providesTags: (result, error, arg) => {
      return result
        ? [
            ...result.map(({ uuid }) => {
              return { type: "DarwinGroup", id: uuid };
            }),
            "DarwinGroup",
          ]
        : ["DarwinGroup"];
    },
  }),

  saveDarwinGroup: builder.mutation({
    query: (body) => {
      return {
        url: `darwin-rank/groups/${
          body.hasOwnProperty("uuid") ? body.uuid : ""
        }`,
        method: body.hasOwnProperty("uuid") ? "PUT" : "POST",
        body,
      };
    },

    invalidatesTags: (result, error, arg) => {
      return [{ type: "DarwinGroup", id: arg.uuid }];
    },
  }),

  loadDarwinGroupMembers: builder.query({
    query: ({ uuid }) => {
      return {
        url: `darwin-rank/placement-group-members/?group_uuid=${uuid}`,
      };
    },

    transformResponse: (response) => {
      return response.records;
    },

    providesTags: (result, error, arg) => {
      return result
        ? [
            ...result.map((r) => {
              return { type: "DarwinGroupMember", id: r.placementUUID };
            }),
            "DarwinGroupMember",
          ]
        : ["DarwinGroupMember"];
    },
  }),

  addDarwinGroupMemeber: builder.mutation({
    query: (body) => {
      return {
        url: `darwin-rank/placement-group-members/`,
        method: "POST",
        body,
      };
    },
    invalidatesTags: (result, error, arg) => {
      return [{ type: "DarwinGroupMember", id: arg.uuid }];
    },
  }),

  deleteDarwinGroupMember: builder.mutation({
    query: ({ placementUUID, groupUUID }) => {
      return {
        url: `darwin-rank/placement-group-members/${placementUUID}`,
        method: "DELETE",
      };
    },
    invalidatesTags: (result, error, arg) => {
      return [{ type: "DarwinGroupMember", id: arg.placementUUID }];
    },
  }),
});
