import React from "react";
import { useLocation } from "react-router-dom";
import Icon from "./Icon";

const DocumentationLink = () => {
  const location = useLocation();

  const docMap = {
    campaigns: "campaign/campaigns",
    "campaigns/new_campaign": "campaign/create-campaigns",
    "campaigns/details": "campaign/campaign-details",
    "campaigns/creative": "campaign/campaign-creative",
    "campaigns/grouping": "campaign/campaign-group",
    "campaigns/questions": "campaign/campaign-questions",
    "campaigns/pre-ping": "campaign/campaign-preping",
    "campaigns/validation": "campaign/campaign-validation",
    "campaigns/delivery": "campaign/campaign-delivery",
    "campaigns/criteria": "campaign/campaign-criteria",
    "campaigns/associations": "campaign/campaign-association",

    placements: "placement/placements",
    "placements/new_placement": "placement/create-placements",
    "placements/details": "placement/placement-details",
    "placements/grouping": "placement/placement-group",

    "ops/tools": "ops_tools/email-suppression",
    "ops/bad_words": "ops_tools/bad-words",
    "ops/blocked_domains": "ops_tools/blocked-domains",
    "ops/blocked_emails": "ops_tools/blocked-emails",
    "ops/blocked_area_codes": "ops_tools/blocked-area-codes",
    "ops/validation_management": "ops_tools/validation-management",
    "ops/contact_management": "ops_tools/contact_management",
    "ops/darwin_groups": "ops_tools/darwin-groups",
    // "ops/ip_lookup": "ops_tools",

    "reports/activity_log": "reports/activity-log",
    "reports/validation": "reports/validations",
  };

  const getDocumentationUrl = (path) => {
    const baseUrl = "https://hq-docs.adquire.com/";

    const uuidRegex =
      /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/g;
    //Remove UUIDs, double //'s and leading /'s:
    const cleanedPath = path.replace(uuidRegex, "").replace("//", "/").slice(1);

    if (docMap[cleanedPath] === undefined) {
      return baseUrl;
    }

    return `${baseUrl}${docMap[cleanedPath]}`; // Map to documentation url
  };

  return (
    <Icon
      icon="help_outline"
      align="left"
      direction="row"
      margin="10px 5px 0px 0px"
      onClick={() => {
        let documentationUrl = getDocumentationUrl(location.pathname);
        // Documentation linkout:
        window.open(documentationUrl);
      }}
    />
  );
};
export default DocumentationLink;
